import React from "react";
import styles from "./Layout.module.scss";

import Navbar from "./Navbar";
import Footer from "./Footer";

import useLocalStorage from "../hooks/useLocalStorage";
import { IPuzzles, PuzzlesIds } from "../puzzles";
import Lock from "../components/Lock";

const Layout: React.FC = ({ children }) => {
  const [solvedPuzzles] = useLocalStorage<IPuzzles>("puzzles", {});

  if (!solvedPuzzles[PuzzlesIds.GREETINGS]) return <Lock />;

  return (
    <div className={styles.layout}>
      <Navbar />
      <div className={styles.content}>
        <div className={styles.page}>{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
