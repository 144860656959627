import React from "react";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import File from "./pages/File";

const App: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Layout>
          <Route path="/file" component={File} />
          <Route path="/" exact component={Home} />
        </Layout>
      </Switch>
    </HashRouter>
  );
};

export default App;
