import armor from "../assets/armor.png";
import brazil from "../assets/brazil.png";
import cat from "../assets/cat.png";
import gambler from "../assets/gambler.png";
import gorilla from "../assets/gorilla2.png";
import grape from "../assets/grape.png";
import korea from "../assets/korea.png";
import paco from "../assets/paco.png";

export interface IPuzzles {
  [id: string]: string;
}

export enum PuzzlesIds {
  GREETINGS = "greetings",
  TUTORIAL = "tutorial",
  CATMAN = "cat-man",
  PACO = "paco",
  QUOTES = "quotes",
  BRAZILMAN = "brazil-man",
  ARMORED = "armored",
  GAMBLER = "gambler",
  GRAPEFRIEND = "grape-friend"
}

export const puzzles: IPuzzles = {
  [PuzzlesIds.GREETINGS]: "peanut",
  [PuzzlesIds.TUTORIAL]: "fuck javascript",
  [PuzzlesIds.CATMAN]: "mousquetaires",
  [PuzzlesIds.PACO]: "la fourchette",
  [PuzzlesIds.QUOTES]: "corée du sud",
  [PuzzlesIds.BRAZILMAN]: "altruisme",
  [PuzzlesIds.ARMORED]: "béhourd",
  [PuzzlesIds.GAMBLER]: "mamba",
  [PuzzlesIds.GRAPEFRIEND]: "imie"
};

export interface IProfile {
  key: number;
  title: string;
  realName: string;
  puzzle: PuzzlesIds;
  avatar: string;
  description: string;
  hint: string;
  revealText: string;
}

export const profiles: IProfile[] = [
  {
    key: 0,
    title: "",
    realName: "agent P. Nut",
    puzzle: PuzzlesIds.TUTORIAL,
    avatar: gorilla,
    description: "",
    hint: "",
    revealText: `Gorilla Corp. s'intéresse à Lone Stone. Tu as été choisi pour enquêter.
Nous avons des indics infiltrés que tu devras retrouver à l'aide de leurs noms de code et de quelques informations.
Chacun(e) te fourniras un colis ainsi qu'un code pour progresser.
Si tu es prêt à commencer ton enquête, clique sur "Démarrer" et découvre ta première cible.\n
Bonne chance !`
  },
  {
    key: 1,
    title: "Patrick Chaton",
    realName: "Pierrick Bignet",
    puzzle: PuzzlesIds.CATMAN,
    avatar: cat,
    description: `Retrouve Patrick Chaton, développeur et game designer. Il te fournira le code te permettant de passer à la cible suivante.
Pour cela, tu devras lui poser la question suivante:
"Quel surnom les Lonestoniens ont-ils donné aux trois co-fondateurs ?"\n
Bonne chance !`,
    hint: "Fondateur",
    revealText: `Pierrick Bignet est l'un des 3 fondateurs de Lone Stone. Il a étudié à l'Effrei puis à Audencia où il a rencontré Godefroy.
C'est de cette rencontre qu'est né le projet City Invaders.
Pierrick est un grand fan de jeux vidéo depuis son plus jeune âge.`
  },
  {
    key: 2,
    title: "Francky Paco",
    realName: "François Robichet",
    puzzle: PuzzlesIds.PACO,
    avatar: paco,
    description: `Chez Lone Stone, les chats sont à l'honneur. Francky Paco est le seul à avoir un chien.
Retrouve le et demande lui le nom de son ancienne boîte, il te permettra de passer au prochain indic.\n
Bonne chance !`,
    hint: "L'un des tout derniers arrivants",
    revealText: `François Robichet a rejoint Lone Stone il y a à peine un mois. Avant cela il était chez La Fourchette.
C'est un fan de cuisine. Il a vécu 4 ans en Australie et sa femme est australienne.`
  },
  {
    key: 3,
    title: "Mr. Quotes",
    realName: "Ronan Letellier",
    puzzle: PuzzlesIds.QUOTES,
    avatar: korea,
    description: `Mr. Quotes est incollable sur les citations, de Kaamelott à OSS 117, il n'a pas d'égal.
Si nombre de Lonestoniens ont trouvé l'amour, seuls deux sont mariés. Mr. Quotes est le plus récent marié. Demande lui de quel pays sa femme est originaire.\n
Bonne chance !`,
    hint: "Il a pas mal voyagé",
    revealText: `Ronan Letellier a rejoint le projet City Invaders à ses débuts et s'est associé à Pierrick et Godefroy pour fonder Lone Stone.
Il a vécu au Vietnam, en Suisse et en Corée du Sud.`
  },
  {
    key: 4,
    title: "Michel, l'homme Brésil",
    realName: "Johan Brun",
    puzzle: PuzzlesIds.BRAZILMAN,
    avatar: brazil,
    description: `Retrouve Michel, l'homme Brésil.
Michel a le coeur sur la main. Et pourtant, une qualité humaine qui lui est souvent prétée n'existe pas d'après lui. Laquelle ?\n
Bonne chance !`,
    hint: "Percé",
    revealText: `Johan Brun est un ancien de Capgemini. Il a rejoint Lone Stone pour son côté humain.
En plus de son métier de développeur, Johan est volontaire à la protection civile. En cas de problème, tu sais qui appeler !`
  },
  {
    key: 5,
    title: "Mar the Kid",
    realName: "Marlène Gray",
    puzzle: PuzzlesIds.ARMORED,
    avatar: armor,
    description: `The Kid est la plus jeune de la bande. C'est aussi l'une des seules filles.
Si certains Lonestoniens pratiquent la boxe thai, Mar the Kid pratique un sport de combat beaucoup plus original.
Elle saura te renseigner sur le nom de ce sport qui débloquera ton prochain objectif.\n
Bonne chance !`,
    hint: "Trop facile celui là",
    revealText: `Marlène Gray est concept artist et animatrice 3D et a rejoint Lone Stone il y a plus d'un an maintenant.
Elle pratique le béhourd et est même en équipe de France féminine ! Mieux vaut pas l'emmerder...`
  },
  {
    key: 6,
    title: "Le parieur",
    realName: "Hervé Hochart",
    puzzle: PuzzlesIds.GAMBLER,
    avatar: gambler,
    description: `Le parieur est un joueur dans l'âme. Ce qu'il aime avec l'argent, c'est surtout le prendre aux autres !
Son pseudo de gamer débloquera la fiche de ta prochaine cible.\n
Bonne chance !`,
    hint: "Grand",
    revealText: `Hervé Hochart est un grand (littéralement) joueur. Sa passion: faire rager ses adversaires. On ne peut pas dire que ça lui ait toujours réussi, mais il faut admettre qu'il se débrouille sur Age of Empires.
Lui et Clarie Chauvière (UX/UI designeuse chez Lone Stone) ont fait la même école.`
  },
  {
    key: 7,
    title: "L'ami du raisin",
    realName: "Kyllian Vinkler",
    puzzle: PuzzlesIds.GRAPEFRIEND,
    avatar: grape,
    description: `L'ami du raisin est l'un des plus jeunes développeurs de la boîte. Il vient de terminer son alternance et de signer son CDI.
Pour en apprendre plus sur lui, demande lui de quel organisme de formation il vient d'obtenir son diplôme.\n
Bonne Chance !`,
    hint: "💪",
    revealText: `Kyllian Vinkler est arrivé chez Lone Stone en alternance alors qu'ils ne recrutaient que des temps complets. Mais son talent a su convaincre, et tout le monde se félicite aujourd'hui d'avoir fait une exception.
Il est à 100% de victoires sur les compétitions Lone Stone (laser tag et karting), ce qui peut en agacer certains...`
  }
];
