import React, { useState } from "react";
import styles from "./Lock.module.scss";
import useLocalStorage from "../../hooks/useLocalStorage";
import { puzzles, IPuzzles, PuzzlesIds } from "../../puzzles";
import classnames from "classnames";
import eye from "../../assets/eye.png";
import closedEye from "../../assets/closed-eye.png";
// @ts-ignore
import Typing from "react-typing-animation";
import { TYPING_SPEED } from "../../config";

interface IProps {
  puzzle?: PuzzlesIds;
}

const Lock: React.FC<IProps> = ({ puzzle = PuzzlesIds.GREETINGS }) => {
  const [solvedPuzzles, setSolvedPuzzles] = useLocalStorage<IPuzzles>(
    "puzzles",
    { [PuzzlesIds.TUTORIAL]: puzzles[PuzzlesIds.TUTORIAL] }
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [type, setType] = useState("password");

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const solution = event.currentTarget.value.toLowerCase();
    if (solution === puzzles[puzzle]) {
      setIsDisabled(true);
      setSolvedPuzzles({
        ...solvedPuzzles,
        [puzzle]: solution
      });

      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  };

  const toggleCode = () => {
    setType(type === "password" ? "text" : "password");
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.fullscreen]: puzzle === PuzzlesIds.GREETINGS
      })}
    >
      <div className={styles.code}>
        <input
          type={type}
          placeholder="code"
          onChange={onChange}
          disabled={isDisabled}
        />
        <img
          src={type === "password" ? eye : closedEye}
          title={type === "password" ? "Afficher le code" : "Masquer le code"}
          onClick={toggleCode}
        />
      </div>
      {isDisabled && (
        <Typing speed={TYPING_SPEED.XSLOW}>
          <div
            className={classnames(styles.success, {
              [styles.visible]: isDisabled
            })}
          >
            Unlocking
          </div>
        </Typing>
      )}
    </div>
  );
};

export default Lock;
