import React from "react";
import styles from "./Footer.module.scss";

const Footer: React.FC = () => {
  return (
    <div className={styles.container}>
      <footer>© Gorilla corp. 2019</footer>
    </div>
  );
};

export default Footer;
