import React from "react";
import styles from "./Avatar.module.scss";

interface IProps {
  onHover?: () => void;
  onOut?: () => void;
}

const Avatar: React.FC<IProps> = ({ onHover, onOut }) => {
  return (
    <div className={styles.container} onMouseOver={onHover} onMouseOut={onOut}>
      <div className={styles.avatar}></div>
    </div>
  );
};

export default Avatar;
