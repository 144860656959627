import React, { useState, useEffect, useMemo } from "react";
import styles from "./Profile.module.scss";
import { IProfile, IPuzzles, profiles } from "../../puzzles";
import useLocalStorage from "../../hooks/useLocalStorage";
import Card from "../Card";
import anonymous from "../../assets/anonymous.png";
import Lock from "../Lock";
import classnames from "classnames";
// @ts-ignore
import Typing from "react-typing-animation";
import { TYPING_SPEED } from "../../config";

interface IProps {
  profile?: IProfile;
}

const Profile: React.FC<IProps> = ({ profile }) => {
  const [solvedPuzzles] = useLocalStorage<IPuzzles>("puzzles", {});
  const [currentPuzzleKey, setCurrentPuzzleKey] = useLocalStorage<number>(
    "currentPuzzle",
    profile ? profile.key : 0
  );
  const [isSolved, setIsSolved] = useState(false);
  const [isTextTyped, setIsTextTyped] = useState(false);

  useEffect(() => {
    if (profile && !!solvedPuzzles[profile.puzzle]) {
      setIsSolved(true);
    }
  }, []);

  const nextProfile = () => {
    if (!isSolved) return;
    setCurrentPuzzleKey(profile ? profile.key + 1 : 0);
    window.location.reload();
  };

  const resetMission = () => {
    localStorage.clear();
    window.location.reload();
  };

  const hasNext = useMemo(() => {
    return profiles.find(p => p.key === currentPuzzleKey + 1);
  }, [profiles]);

  const text = useMemo(() => {
    if (!profile) return "";

    return isSolved
      ? profile.key > 0
        ? `Bravo !\n\n${profile.revealText}`
        : profile.revealText
      : profile.description;
  }, [profile, isSolved]);

  if (!profile) return <></>;

  return (
    <div className={styles.container}>
      <Card
        avatar={isSolved ? profile.avatar : anonymous}
        name={isSolved ? profile.realName : profile.title}
      />
      <Typing
        speed={TYPING_SPEED.FAST}
        onFinishedTyping={() => setIsTextTyped(true)}
      >
        <Typing.Delay ms={2000} />

        <div className={styles.description}>{text}</div>
      </Typing>
      <div
        className={classnames({
          [styles.resolver]: true,
          [styles.fadeIn]: isTextTyped,
          [styles.tutorial]: profile.key === 0
        })}
      >
        {!isSolved && <Lock puzzle={profile.puzzle} />}
        {isSolved && hasNext && (
          <div className={styles.nextProfile}>
            <input
              type="button"
              value={profile.key === 0 ? "Démarrer" : "Cible suivante ››"}
              onClick={nextProfile}
            />
          </div>
        )}
        {isSolved && !hasNext && (
          <div className={styles.end}>
            <p>
              Félicitations, tu as trouvé tous les indics !<br />
              Cette enquête t'aura permis d'en apprendre un peu plus sur tes
              voisins.
              <br />À jeudi !
            </p>
            <input type="button" value="Recommencer" onClick={resetMission} />
          </div>
        )}
      </div>

      {!isSolved && <div className={styles.hint}>Indice: {profile.hint}</div>}
    </div>
  );
};

export default Profile;
