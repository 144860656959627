import React, { useMemo } from "react";
import styles from "./Home.module.scss";
import {
  profiles,
  IPuzzles,
  IProfile,
  PuzzlesIds,
  puzzles
} from "../../puzzles";
import Profile from "../../components/Profile";
import useLocalStorage from "../../hooks/useLocalStorage";

const Home: React.FC = () => {
  const [solvedPuzzles] = useLocalStorage<IPuzzles>("puzzles", {});

  const [currentPuzzleKey] = useLocalStorage<number>("currentPuzzle", 0);

  const profile = profiles.find(p => p.key === currentPuzzleKey);
  const prevProfile = profiles.find(p => p.key === currentPuzzleKey - 1);

  if (prevProfile && !Object.keys(solvedPuzzles).includes(prevProfile.puzzle))
    return <></>;

  return (
    <div className={styles.container}>
      <Profile profile={profile} />
    </div>
  );
};

export default Home;
