import React, { useEffect, useMemo, useState } from "react";
import styles from "./File.module.scss";
import { IPuzzles, puzzles, profiles } from "../../puzzles";
import useLocalStorage from "../../hooks/useLocalStorage";
import { withRouter, RouteComponentProps } from "react-router";
import queryString from "query-string";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
// @ts-ignore
import Typing from "react-typing-animation";
import { TYPING_SPEED } from "../../config";

const File: React.FC<RouteComponentProps> = ({ history, location }) => {
  const [solvedPuzzles] = useLocalStorage<IPuzzles>("puzzles", {});
  const { key } = queryString.parse(location.search);

  const profile = useMemo(() => {
    if (typeof key !== "string") return false;

    const numKey = parseInt(key, 10);
    return profiles.find(p => p.key === numKey);
  }, [key]);

  const isFileUnlocked = useMemo(() => {
    if (!profile || !Object.keys(solvedPuzzles).includes(profile.puzzle))
      return false;

    return true;
  }, [profile, solvedPuzzles]);

  useEffect(() => {
    if (!key || typeof key !== "string" || !isFileUnlocked || key === "0") {
      history.push("/");
    }
  }, [isFileUnlocked]);

  if (!profile) return <></>;

  return (
    <div className={styles.container}>
      <Link to="/" className={styles.backButton}>
        ‹‹ Retour
      </Link>
      <Card avatar={profile.avatar} name={profile.realName} animate={false} />

      {/* <Typing speed={TYPING_SPEED.FAST}>
        <Typing.Delay ms={2000} /> */}
      <div className={styles.description}>{profile.revealText}</div>
      <div className={styles.infos}>
        <br />
        Indice: {profile.hint}
        <br />
        Code: {puzzles[profile.puzzle]}
      </div>
      {/* </Typing> */}
    </div>
  );
};

export default withRouter(File);
