import React, { useState } from "react";
import styles from "./Navbar.module.scss";
import useLocalStorage from "../../hooks/useLocalStorage";

import Avatar from "../../components/Avatar";
import { IPuzzles, profiles } from "../../puzzles";

import { Link } from "react-router-dom";

// @ts-ignore
import Typing from "react-typing-animation";
import { TYPING_SPEED } from "../../config";

const Navbar: React.FC = () => {
  const [solvedPuzzles] = useLocalStorage<IPuzzles>("puzzles", {});

  const [isHovering, setIsHovering] = useState(false);

  const targetsFound = profiles.filter(
    p => p.key > 0 && Object.keys(solvedPuzzles).includes(p.puzzle)
  );

  return (
    <div className={styles.container}>
      <div className={styles.leftSpace}></div>
      <div className={styles.agent}>
        <Avatar
          onHover={() => setIsHovering(true)}
          onOut={() => setIsHovering(false)}
        />
        &nbsp;&nbsp; › {!isHovering && <>Agent P. Nut</>}
        {isHovering && (
          <Typing speed={TYPING_SPEED.SLOW}>&nbsp;Jérémie Cousin</Typing>
        )}
        {isHovering}
        <span className={styles.blink}>_</span>
      </div>
      <div className={styles.targets}>
        <span className={styles.photos}>
          {targetsFound.map(tf => (
            <Link key={tf.key} to={`/file?key=${tf.key}`}>
              <img src={tf.avatar} width={40} height={40} title={tf.realName} />
            </Link>
          ))}
        </span>
      </div>
    </div>
  );
};

export default Navbar;
