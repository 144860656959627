import React from "react";
import styles from "./Card.module.scss";
import classnames from "classnames";
import useLocalStorage from "../../hooks/useLocalStorage";
// @ts-ignore
import Typing from "react-typing-animation";
import { TYPING_SPEED } from "../../config";

interface IProps {
  avatar: string;
  name: string;
  animate?: boolean;
}

const Card: React.FC<IProps> = ({ avatar, name, animate = true }) => {
  const [currentPuzzleKey] = useLocalStorage<number>("currentPuzzle", 0);

  return (
    <div className={styles.container}>
      <div className={classnames(styles.photo, { [styles.fadeIn]: animate })}>
        <img src={avatar} width={100} height={100} className={styles.avatar} />
      </div>
      {currentPuzzleKey === 0 && (
        <Typing speed={TYPING_SPEED.SLOW}>
          <div className={styles.name}>
            Bienvenue Jérém
            <Typing.Delay ms={500} />
            <Typing.Backspace count={5} />
            {name}
          </div>
        </Typing>
      )}
      {currentPuzzleKey > 0 && animate && (
        <Typing speed={TYPING_SPEED.SLOW}>
          <div className={styles.name}>{name}</div>
        </Typing>
      )}
      {!animate && <div className={styles.name}>{name}</div>}
    </div>
  );
};

export default Card;
