import { useState } from "react";

export default <T>(item: string, defaultValue: T): [T, (val: T) => void] => {
  const [value, setValue] = useState<T>(() => {
    const storageDefault = localStorage.getItem(item);
    return storageDefault === null ? defaultValue : JSON.parse(storageDefault);
  });

  return [
    value,
    (newValue: T) => {
      setValue(newValue);
      localStorage.setItem(item, JSON.stringify(newValue));
    }
  ];
};
